import { useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { Search as SearchIcon } from '@styled-icons/boxicons-regular/Search';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Helmet } from 'react-helmet';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import dynamic from 'next/dynamic';

import AppBanner from '@/components/app-banner';
import Banner from '@/components/banner';
import HowItWorks from '@/components/how-it-works';
import Loader from '@/components/loader';
import Button from '@/components/button';
import { media } from '@/themes';
import { searchBrands } from '@/api';
import { getAllBrandsAndTopupsFromResults } from '@/utils';

const BrowseMore = dynamic(() => import('@/components/browse-more'), { ssr: true });
const Testimonials = dynamic(() => import('@/components/testimonials'), { ssr: true });
const SearchField = dynamic(() => import('@/components/search-field'), { ssr: false });
// const AppBanner = dynamic(() => import('@/components/app-banner'), { ssr: false });

const SearchWrapper = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 1.5em;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  display: flex;
  flex-direction: row;
  height: 3em;
  margin: 0 auto 2em;
  position: relative;
  transition-duration: 125ms;
  transition-property: transform, box-shadow;
  width: 100%;
  will-change: transform, box-shadow;
  z-index: 2;

  ${media.sm`
    max-width: none;
  `};

  ${({ $isFocused, theme }) => $isFocused && css`
    box-shadow: ${theme.shadows.hover};
    transform: translateY(-.125em);
  `};
`;

const BannerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  max-width: 100%;

  ${media.md`
    flex-direction: column-reverse;
  `};
`;

const LeftColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2em;
  max-width: 50%;

  ${media.md`
    margin: 0;
    max-width: 100%;
  `};
`;

const RightColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.md`
    margin: 2em 0 2em;
    max-width: 66vw;
  `};

  ${media.sm`
    max-width: 55vw;
  `};
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.primary};
  margin-top: 0;
  text-align: left;
  transform: translate3d(0, 0, 0);

  ${media.md`
    font-size: 1.75em;
  `};

  ${media.sm`
    font-size: 1.5em;
  `};
`;

const GoButton = styled.button`
  background: ${props => props.theme.colors.accent};
  border: none;
  border-radius: 1.5em;
  color: white;
  cursor: pointer;
  font-size: .85em;
  font-weight: 600;
  height: 3em;
  margin-left: auto;
  margin-right: .25em;
  padding: 0;
  position: relative;
  width: 3em;
  z-index: 2;

  &:focus {
    outline: none;
  }
`;

const Canvas = styled.canvas`
  clip-path: ellipse(100% 100% at center top);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  ${media.md`
    clip-path: ellipse(150% 100% at center top);
  `};

  ${media.sm`
    clip-path: ellipse(200% 100% at center top);
  `};
`;

const GradientPlaceholder = styled.div`
  background:
    linear-gradient(25deg, #EBE6FB, #EBE6FB00 30%),
    linear-gradient(-100deg, #E2FAD5, #E2FAD500 25%),
    linear-gradient(145deg, #F5E8DA, #FAEDFD 16%, #FAEDCF);
  clip-path: ellipse(100% 100% at center top);
  height: 100%;
  left: 0;
  opacity: ${props => props.$contentLoaded ? 0 : 1};
  position: absolute;
  top: 0;
  transition: opacity 3s;
  width: 100%;
  z-index: -1;

  ${media.md`
    clip-path: ellipse(150% 100% at center top);
  `};

  ${media.sm`
    clip-path: ellipse(200% 100% at center top);
  `};
`;

const delayedCall = (fn, ms) => () => {
  setTimeout(fn, ms);
}

const Index = observer(({ }) => {
  const router = useRouter();
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [cachedSuggestions, setCachedSuggestions] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const canvas = useRef(null);
  const theme = useTheme();
  const t = useTranslations('home');

  const store = useLocalObservable(() => ({
    searchTerm: '',
    setSearchTerm (e) {
      this.searchTerm = e.target.value;
    }
  }));

  const search = () => {
    const trimmedQuery = store.searchTerm.trim();
    setIsLoading(true);

    if (trimmedQuery !== '' && trimmedQuery.length > 2) {
      router.push(`/buy?q=${encodeURIComponent(store.searchTerm.trim())}`);
    } else {
      router.push('/buy');
    }
  };

  const handleOnChange = async event => {
    const { value } = event.target;
    setSuggestions([]);

    if (value.trim()) {
      setIsLoadingSuggestions(true);
      const { results } = await searchBrands({ $search: value });
      const suggestions = getAllBrandsAndTopupsFromResults(results);

      setSuggestions(suggestions.sort(brand => !brand.name.startsWith(value)));
      setIsLoadingSuggestions(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);

    if (cachedSuggestions.length > 0) {
      setSuggestions(cachedSuggestions);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    setCachedSuggestions(suggestions);
    setTimeout(() => setSuggestions([]), 250);
  };

  useEffect(() => {
    let run = () => {};

    if (canvas.current) {
      const c = canvas.current;
      const $ = c.getContext('2d');
      const saturation = 32;
      const brightness = 232;
      let t = 0;

      const col = function(x, y, r, g, b) {
        $.fillStyle = `rgb(${r}, ${g}, ${b})`;
        $.fillRect(x, y, 1,1);
      };

      const R = function(x, y, t) {
        return (
          Math.floor(brightness + saturation * Math.cos((x * x - y * y) / 300 + t))
        );
      };

      const G = function(x, y, t) {
        return (
          Math.floor(brightness + saturation * Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 300))
        );
      };

      const B = function(x, y, t) {
        return (
          Math.floor(brightness + saturation * Math.sin(5 * Math.sin(t / 9) + ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100))
        );
      };

      const start = function() {
        for (let x = 0; x <= 35; x++) {
          for (let y = 0; y <= 35; y++) {
            col(x, y, R(x, y, t), G(x, y, t), B(x, y, t));
          }
        }
        t = t + 0.025;

        window.requestAnimationFrame(start);
      };

      run = delayedCall(function() {
        start();
        setContentLoaded(true);
      }, 12000);

      if (typeof window !== 'undefined') {
        window.addEventListener('DOMContentLoaded', run);
      }
    }

    return () => window.removeEventListener('DOMContentLoaded', run);
  }, [canvas]);

  return <>
    <Helmet bodyAttributes={{ class: 'home' }} />
    <Banner background="transparent" taglineStyle={{ padding: 0 }}>
      <Canvas height={32} ref={canvas} width={32} />
      <GradientPlaceholder $contentLoaded={contentLoaded} />
      <BannerContainer>
        <LeftColumn>
          <Title>
            {t.rich('heroTitle', { strong: children => <strong>{children}</strong>  })}
          </Title>
          <SearchWrapper $isFocused={isFocused}>
            <SearchField
              isLoadingSuggestions={isLoadingSuggestions}
              onBlur={handleBlur}
              onChange={handleOnChange}
              onEnter={search}
              onFocus={handleFocus}
              onSuggestionClick={() => setIsLoading(true)}
              search={() => { }}
              searchTerm={store.searchTerm}
              setSearchTerm={store.setSearchTerm}
              suggestions={suggestions} />
            <GoButton onClick={search} name="Go / Search">
              {!isLoading && <SearchIcon size="1.5em" />}
              {isLoading && <Loader color="white" size={24} />}
            </GoButton>
          </SearchWrapper>
          <Link href="/buy" passHref>

            <Button
              accent
              isLoading={isLoading}
              onClick={() => setIsLoading(true)}>
              {t('browseAll')}
          </Button>

          </Link>
        </LeftColumn>
        <RightColumn>
          <Image
            alt="Mobile shopping"
            height={410}
            priority
            src="/images/stuff.webp"
            unoptimized
            width={500}
            style={{
              maxWidth: "100%",
              height: "auto",
              width: "100%"
            }} />
        </RightColumn>
      </BannerContainer>
    </Banner>
    <HowItWorks />
    <BrowseMore />
    <Testimonials />
    <AppBanner background={theme.colors.primary} inverted />
  </>;
});

export async function getStaticProps ({ locale }) {
  return {
    props: {
      hideCurrencies: true,
      messages: require(`@/locales/${locale}.json`)
    }
  };
}

export default Index;
